export const CHAT_TYPE_IMAGE = 'image';
export const CHAT_TYPE_CHAT = 'chat';

type iMessage = {
  role: 'User' | 'System';
  content: string;
  isLoading?: boolean;
}

export default iMessage;
