import styled from 'styled-components';
import AppLogo from '../Components/AppLogo';
import {useSearchParams} from 'react-router-dom';
import Alert from '../Components/Form/Alert';
import {useState, useEffect} from 'react';
import WeChatService from '../Services/WeChatService';
import message from '../Components/Form/message';
import AuthService from '../Services/AuthService';
import {BACK_END_URL} from '../UrlMap';
import Spin from '../Components/Form/Spin';

const Wrapper = styled.div`
  text-align: center;
  .login-form {
    max-width: 400px;
    margin: 2rem auto;
  }
`;
const WeChatAuthPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [searchParams] = useSearchParams();
  const state = `${searchParams.get('state') || ''}`.trim();

  useEffect(() => {
    let isCanceled = false;
    if (state === '') { return }
    setIsLoading(true);
    WeChatService.getUserFromQrcode(state)
      .then(resp => {
        if (isCanceled) return;
        const authToken = `${resp.token || ''}`.trim();
        if (authToken !== '') {
          setUserToken(authToken)
          return;
        }
        if (resp.error !== null) {
          message.error(`${resp.error.message || 'Error Occurred'}`);
        }
        setIsLoading(false);
      })
      .catch(err => {
        if (isCanceled) { return }
        message.error(err.response.data.message);
      })
    return () => {
      isCanceled = true;
    };
  }, [state]);


  if (`${userToken || ''}`.trim() !== '') {
    AuthService.setLocalAuthToken(`${userToken || ''}`.trim());
    window.location.href = BACK_END_URL;
    return null;
  }

  const getContent = () => {
    if (state === '') {
      return (
        <Alert type={'error'} message={'Invalid State!'} />
      )
    }
    return <Spin spinning={isLoading}/>
  }
  return (
    <Wrapper>
      <div>
        <AppLogo />
        {getContent()}
      </div>
    </Wrapper>
  );
}

export default WeChatAuthPage;
