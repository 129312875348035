import Locale from '../../Components/Form/Locale';

const LanguageCN = {
  [Locale.zhCN.locale]: {
    homePage: '首页',
    yes: '是的',
    pageNotExists: '抱歉，您访问的页面不存在。',
    login: '登陆',
    weChatLoginTitle: '您可以使用手机微信扫描下方二维码。',
    confirmingLogout: '确定登出？',
    userQuestionBoxPlaceHolder: '输入您的问题',
    copyToClipboard: '点击复制',
    copyToClipboardSucc: '复制成功',
    chat: '聊天',
    image: '图片',
    thinking: '思考中...',
  }
}

export default LanguageCN;
