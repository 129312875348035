import {
  UserOutlined,
  LockOutlined,
  LoginOutlined,
  UploadOutlined,
  DeleteOutlined,
  MailOutlined,
  SendOutlined,
  WechatFilled,
  LockFilled,
  SyncOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PlusOutlined,
  DownOutlined,
  AuditOutlined,
  ApiOutlined,
  ArrowDownOutlined,
  CommentOutlined,
  WarningOutlined,
  TrademarkCircleFilled,
  FlagFilled,
  CompassFilled,
  MailFilled,
  PhoneFilled,
  EnvironmentFilled,
  LinkOutlined,
  DownloadOutlined,
  ToTopOutlined,
  PhoneOutlined,
  GlobalOutlined,
  FacebookFilled,
  GoogleSquareFilled,
  TwitterSquareFilled,
  LinkedinFilled,
  RightOutlined,
  ApartmentOutlined,
  SettingOutlined,
  FileOutlined,
  SmileOutlined,
  SettingFilled,
  DashboardFilled,
  FileFilled,
  CloudUploadOutlined,
  EyeOutlined,
  AreaChartOutlined,
  OrderedListOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  ReloadOutlined,
} from '@ant-design/icons'

const Icons = {
  UserOutlined,
  LockOutlined,
  LoginOutlined,
  UploadOutlined,
  DeleteOutlined,
  MailOutlined,
  SendOutlined,
  WechatFilled,
  LockFilled,
  SyncOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PlusOutlined,
  DownOutlined,
  AuditOutlined,
  ApiOutlined,
  ArrowDownOutlined,
  CommentOutlined,
  WarningOutlined,
  TrademarkCircleFilled,
  FlagFilled,
  CompassFilled,
  MailFilled,
  PhoneFilled,
  EnvironmentFilled,
  LinkOutlined,
  DownloadOutlined,
  ToTopOutlined,
  PhoneOutlined,
  GlobalOutlined,
  FacebookFilled,
  GoogleSquareFilled,
  TwitterSquareFilled,
  LinkedinFilled,
  RightOutlined,
  ApartmentOutlined,
  SettingOutlined,
  FileOutlined,
  SmileOutlined,
  SettingFilled,
  DashboardFilled,
  FileFilled,
  CloudUploadOutlined,
  EyeOutlined,
  AreaChartOutlined,
  OrderedListOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  ReloadOutlined,
}

export default Icons
