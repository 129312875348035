import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
  .title {
    font-size: 24px;
  }
`;
const InitMessagePanel = () => {
  return (
    <Wrapper>
      <h1 className={'title'}>{process.env.REACT_APP_APP_NAME}</h1>
    </Wrapper>
  )
}

export default InitMessagePanel;
