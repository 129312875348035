import styled from 'styled-components';
import WeChatLoginForm from '../Components/Auth/WeChatLoginForm';
import WeChatService from '../Services/WeChatService';
import WeChatLoginBtn from '../Components/Auth/WeChatLoginBtn';
import AppLogo from '../Components/AppLogo';
const Wrapper = styled.div`
  text-align: center;
  .login-form {
    max-width: 400px;
    margin: 2rem auto;
  }
`;
const MainPage = () => {
  return (
    <Wrapper>
      <div>
        <AppLogo />
      </div>
      {WeChatService.isInWeChat() === true ? <WeChatLoginBtn className={'login-form'}/> : <WeChatLoginForm className={'login-form'}/>}
    </Wrapper>
  );
}

export default MainPage;
