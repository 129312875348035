import { Select as AntdSelect, SelectProps } from 'antd'
import {OptionProps} from 'antd/es/mentions';

type iSelect = SelectProps
const Select = ({ className, ...props }: iSelect) => {
  return <AntdSelect {...props} className={`app-Select ${className || ''}`} />
}

export const Option = ({className, ...props}: OptionProps) => {
  return <AntdSelect.Option {...props} className={`app-Select-Option ${className || ''}`} />
}

export default Select
