export const LOGIN_URL = '/';
export const WECHAT_LOGIN_AUTH_URL = '/wechat';
export const BACK_END_URL = '/b/';

const getUrl = (url: string, searching = ':id', replacement = 'new'): string => {
  const re = new RegExp(searching, 'g')
  return url.replace(re, replacement)
}

const getFullUrl = (url: string, params: { [key: string]: string } = {}) => {
  const queryString = new URLSearchParams(params).toString()
  return `${process.env.REACT_APP_PUBLIC_URL}${url}${queryString === '' ? '' : `?${queryString}`}`
}

export const getCurrentUrl = () => window.location.href;


export default {
  getUrl,
  getFullUrl,
}

