import AppService, {iConfigParams} from './AppService'
const endPoint = '/ai'

const chat = async (msg: string, config?: iConfigParams) => {
  return AppService.post(`${endPoint}/chat`, {message: msg}, config).then((resp) => resp.data)
}

const genImage = async (msg: string, config?: iConfigParams) => {
  return AppService.post(`${endPoint}/image`, {message: msg, responseFormat: 'url'}, config).then((resp) => resp.data)
}

const chatStream = async (msg: string, config?: iConfigParams) => {
  return AppService.fetchData(`${endPoint}/chat_stream`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    // eslint-disable-next-line camelcase
    body: JSON.stringify({message: msg}),
    ...config,
  })
}

const AiService = {
  chat,
  chatStream,
  genImage,
}

export default AiService
