import React from 'react'
import { useIntl } from 'react-intl';
import Result from '../../Components/Form/Result'
import Button from '../../Components/Form/Button'

const Page404 = () => {
  const intl = useIntl();
  return (
    <Result
      status='404'
      title='404'
      subTitle={intl.formatMessage({ id: 'pageNotExists' })}
      extra={
        <Button type='primary' href='/'>
          {intl.formatMessage({ id: 'homePage' })}
        </Button>
      }
    />
  )
}

export default Page404
