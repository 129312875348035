import AppWrapper, { AppCssWrapper } from './Styles/AppWrapper'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Page404 from './Pages/Error/Page404'
import React from 'react'
import MainPage from './Pages/MainPage';
import {BACK_END_URL, LOGIN_URL, WECHAT_LOGIN_AUTH_URL} from './UrlMap';
import ProtectedRoute from './Routes/ProtectedRoute';
import LoginPage from './Pages/LoginPage';
import WeChatAuthPage from './Pages/WeChatAuthPage';

const Router = () => {
  return (
    <>
      <AppCssWrapper />
      <AppWrapper className={'app-wrapper'}>
        <BrowserRouter>
          <Routes>
            <Route path={LOGIN_URL} element={<LoginPage />} />
            <Route path={WECHAT_LOGIN_AUTH_URL} element={<WeChatAuthPage />} />
            <Route path={BACK_END_URL} element={
              <ProtectedRoute>
                <MainPage />
              </ProtectedRoute>
            } />
            <Route path='*' element={<Page404 />} />
          </Routes>
        </BrowserRouter>
      </AppWrapper>
    </>
  )
}

export default Router
