import React from 'react'
import { useSelector } from 'react-redux'
// import { BackendPageState, toggleSideMenu } from '../Redux/reduxers/backendpage.slice'
import { RootState } from '../Redux/makeReduxStore'
import { contentInternalPadding } from '../Styles/AppWrapper'
import { Header } from '../Components/Form/Layout'
import Row from '../Components/Form/Row'
import Col from '../Components/Form/Col'
import Space from '../Components/Form/Space'
import UserAvatar from '../Components/User/UserAvatar';
import LogoutBtn from '../Components/Auth/LogoutBtn';
import AppLogo from '../Components/AppLogo';

type iBackendPageHeader = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title?: any
}
/**
 * Backend Page Header
 * @param children
 */
const BackendPageHeader = ({title} : iBackendPageHeader) => {
  // const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.auth)
  // const { collapsed } = useSelector((state: { backendPage: BackendPageState }) => state.backendPage)

  /**
   * toggle side menu
   */
  // const toggleMenu = () => {
  //   dispatch(toggleSideMenu())
  // }

  /**
   * render
   */
  return (
    <Header
      className='backend-page-header'
      style={{
        padding: `0px ${contentInternalPadding}`,
        background: '#fff',
      }}
    >
      <Row>
        <Col flex={'auto'}>
          <AppLogo width={50}/>
        </Col>
        {title}
        <Col
          flex={'100px'}
          style={{
            textAlign: 'right',
          }}
        >
          <Space align={'center'}>
            <UserAvatar user={user} />
            <LogoutBtn />
          </Space>
        </Col>
      </Row>
    </Header>
  )
}

export default BackendPageHeader
