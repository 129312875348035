import styled, { createGlobalStyle } from 'styled-components'
export const APP_WIDTHS = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
}

export const STYLE_WECHAT_COLOR = 'rgb(123, 179, 46)'
export const primaryBtnBgColor = '#1890ff'
export const dangerColor = '#ff4d4f'

export const pageMinHeight = '100vh'
export const contentSideMargin = '16'
export const contentInternalMargin = `${contentSideMargin}px`
export const contentInternalPadding = `${contentSideMargin}px`

export const inlineInputPadding = '13px 22px'
export const inlineInputPaddingHalf = '6.5px 11px'
export const inlineInputBgColor = '#fafafa;'

export const FormInputWrapper = styled.div`
  margin-bottom: 10px;
  width: 100%;
  &.danger {
    color: ${dangerColor};
    .input-wrapper {
      border: 1px ${dangerColor} solid;
    }
  }
  .danger {
    color: ${dangerColor};
    &.input-wrapper {
      border: 1px ${dangerColor} solid;
    }
  }
`

export const InlineInputDiv = styled.div`
  border: none;
  padding: ${inlineInputPadding};
  :hover {
    background: ${inlineInputBgColor};
  }
`

export const FlexContainer = styled.div`
  display: flex;

  &.justify-content-space-between {
    justify-content: space-between;
  }

  &.with-gaps {
    > * {
      padding: 5px;
    }
  }
`

export const Container = styled.div`
  max-width: 800px;
  margin: 0px auto;
`

export const listGutter = 16

export const AppCssWrapper = createGlobalStyle`
  @media only screen and (max-width: ${APP_WIDTHS.sm}) and (min-width: ${APP_WIDTHS.xs}) {
    .hidden-sm {
      display: none;
    }
  }
  @media only screen and (max-width: ${APP_WIDTHS.md}) and (min-width: ${APP_WIDTHS.sm}) {
    .hidden-md {
      display: none;
    }
  }
  @media only screen and (max-width: ${APP_WIDTHS.lg}) and (min-width: ${APP_WIDTHS.md}) {
    .hidden-lg {
      display: none;
    }
  }
  @media only screen and (max-width: ${APP_WIDTHS.xl}) and (min-width: ${APP_WIDTHS.lg}) {
    .hidden-xl {
      display: none;
    }
  }
  @media only screen and (min-width: ${APP_WIDTHS.xl}) {
    .hidden-xxl {
      display: none;
    }
  }

  body {
    margin: 0px !important;
  }

`

const AppWrapper = styled.div`
  
  .backend-page-header {
    background: black;
    .logo-img {
      width: 80px;
      height: auto;
    }
  }
`

export default AppWrapper
