import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Button from '../Form/Button';
import {useIntl} from 'react-intl';
import Icons from '../Icons';
import WeChatService from '../../Services/WeChatService';
import UrlMap, {WECHAT_LOGIN_AUTH_URL} from '../../UrlMap';
import message from '../Form/message';

const Wrapper = styled.div``;

type iWeChatLoginBtn = {
  className?: string;
}
/**
 * WeChatLoginForm
 * @constructor
 */
const WeChatLoginBtn = ({className}: iWeChatLoginBtn) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!WeChatService.isInWeChat()) { return }
    let isCanceled = false;
    setIsLoading(true);
    WeChatService.getWeChatInAppLoginUrl(UrlMap.getFullUrl(WECHAT_LOGIN_AUTH_URL))
      .then(resp => {
        if (isCanceled) return;
        if ('url' in resp && `${resp.url || ''}`.trim() !== '') {
          window.location.href = resp.url
        }
      })
      .catch(err => {
        if (isCanceled) return;
        message.error(err.response.data.message);
      })
      .finally(() => {
        if (isCanceled) return;
        // setIsLoading(false);
      });

    return () => {
      isCanceled = true;
    };
  }, []);
  /**
   * render
   */
  return (
    <Wrapper className={className}>
      <Button type={'primary'} size={'large'} loading={isLoading}>
        <Icons.WechatFilled />
        {intl.formatMessage({id: 'login'})}
      </Button>
    </Wrapper>
  );
}

export default WeChatLoginBtn
