import Locale from '../../Components/Form/Locale';

const LanguageEN = {
  [Locale.enUS.locale]: {
    homePage: 'Home Page',
    pageNotExists: 'Sorry, the page you visited does not exist.',
    login: 'login',
    weChatLoginTitle: 'You can use your WeChat App on your mobile to scan the QRCode below.',
    confirmingLogout: 'Are you sure？',
    copyToClipboard: 'Click to copy',
    copyToClipboardSucc: 'Copied successfully',
    chat: 'Chat',
    image: 'Image',
    thinking: 'thinking...',
  }
}


export default LanguageEN;
