import iMessage, {CHAT_TYPE_CHAT} from '../../Types/iMessage';
import styled from 'styled-components';
import MessageRow from './MessageRow';

type iMessagesPanel = {
  messages: iMessage[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
  chatType?: string;
};

const Wrapper = styled.div`
  
`;
const MessagesPanel = ({messages, children, chatType = CHAT_TYPE_CHAT}: iMessagesPanel) => {
  return (
    <Wrapper>
      {messages.map((message, index) => {
        return <MessageRow msg={message} key={index} chatType={chatType} />;
      })}
      {children}
    </Wrapper>
  )
}

export default MessagesPanel;
