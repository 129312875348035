import LocalStorageService from './LocalStorageService'
import IUser from '../Types/User/IUser'
import AppService, { iConfigParams } from './AppService'
import ICompanyUser from '../Types/Company/ICompanyUser';

const endPoint = '/auth'
const setLocalAuthToken = (authToken: string) => {
  LocalStorageService.setToken(authToken)
}

const getLocalAuthToken = () => {
  return LocalStorageService.getToken()
}

const removeLocalAuthToken = () => {
  LocalStorageService.removeToken()
}

const login = async (email: string, password: string) => {
  return AppService.post(endPoint, { email, password }).then((resp) => resp.data)
}

type iMeData = {
  user: IUser,
  companies: ICompanyUser[];
}

const getMe = async (config: object | undefined = undefined): Promise<iMeData> => {
  return AppService.get(`${endPoint}/me`, {}, config).then((res) => res.data)
}

const updateMe = async (data: iConfigParams, config?: iConfigParams): Promise<iMeData> => {
  return AppService.put(`${endPoint}/me`, data, config).then((res) => res.data)
}

const logout = async (data?: iConfigParams, config?: iConfigParams): Promise<iMeData> => {
  return AppService.delete(`${endPoint}`, data, config).then((res) => res.data)
}

const AuthService = {
  login,
  getMe,
  updateMe,
  setLocalAuthToken,
  getLocalAuthToken,
  removeLocalAuthToken,
  logout,
}

export default AuthService
