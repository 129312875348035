import {Container} from '../../Styles/AppWrapper';
import UserAvatar from '../User/UserAvatar';
import AppLogo from '../AppLogo';
import styled from 'styled-components';
import iMessage, {CHAT_TYPE_CHAT} from '../../Types/iMessage';
import message from '../Form/message';
import Button from '../Form/Button';
import {useSelector} from 'react-redux';
import {RootState} from '../../Redux/makeReduxStore';
import {useIntl} from 'react-intl';
import React from 'react';
import WeChatService from '../../Services/WeChatService';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Image from '../Form/Image';

const Wrapper = styled.div`
  &.loading {
    .content p::after {
      content: '';
      display: inline-block;
      background: #6c757d;
      width: 10px;
      height: 13px;
      animation: blinker 0.5s linear infinite;
    }
  }

  .container {
    display: flex;
    padding: 1rem 0;
  }
  &.user {
    background: white;
  }

  .icon {
    width: 50px;
    .logo-img {
      width: 30px !important;
      height: auto;
    }
    .app-Avatar,
    .user-avatar {
      margin: 8px;
    }
  }
  .content {
    flex: auto;
    max-width: calc(100% - 60px);
    padding: 0.5rem ;
    p {
      line-height: 1.3rem;
      margin: 0px;
    }
    pre {
      code {
        display: block;
        background: black;
        color: white;
        overflow: auto;
        padding: 0.6rem;
      }
    }
    .response-img {
      width: 200px;
      height: auto;
    }
    .copy-btn {
      margin-top: 0.5rem;
    }
  }
`;

type iMessageRow = {
  msg?: iMessage | null;
  id?: string;
  chatType?: string;
}
const MessageRow = ({msg, id, chatType = CHAT_TYPE_CHAT} : iMessageRow) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const intl = useIntl();

  const copyToClipboard = (event: React.MouseEvent<HTMLElement>, content: string) => {
    if (WeChatService.isInWeChat()) {
      const el = document.createElement('textarea');
        el.value = content;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    } else {
      // console.log('not in wechat')
      navigator.clipboard.writeText(content);
    }
    message.success(intl.formatMessage({id: 'copyToClipboardSucc'}))
  }

  const getCopyBtn = (message: iMessage) => {
    if (message.isLoading === true || `${message.content || ''}`.trim() === '' || message.role.toLowerCase() !== 'system' ) {
      return null;
    }
    return (
      <Button type={'link'} size={'small'} className={'copy-btn'} onClick={(event) => copyToClipboard(event, message.content)}>
        {intl.formatMessage({id: 'copyToClipboard'})}
      </Button>
    )
  }


  if (!msg) {
    return null;
  }

  return (
    <Wrapper className={`msg-row ${msg.role.toLowerCase()} ${msg.isLoading === true ? 'loading' : ''}`}>
      <Container className={'container'}>
        <div className={'icon'}>
          {msg.role.toLowerCase() === 'user' ? <UserAvatar user={user} className={'user-avatar'}/> : <AppLogo />}
        </div>
        <div className={'content'} id={id}>
          {
            chatType === CHAT_TYPE_CHAT ? (
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {msg.content || ''}
              </ReactMarkdown>
            ) : (msg.role.toLowerCase() === 'user' ? msg.content : <Image src={msg.content} className="response-img" width={200}/>)
          }

          {chatType === CHAT_TYPE_CHAT ? getCopyBtn(msg) : null}
        </div>
      </Container>
    </Wrapper>
  )
}

export default MessageRow;
