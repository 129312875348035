import React from 'react'
import Result from './Form/Result'
import Icons from './Icons'

const AppInit = () => {
  return (
    <Result icon={<Icons.SyncOutlined spin />} />
  )
}

export default AppInit
