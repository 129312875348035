import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import AppFooter from '../Components/AppFooter'
import { RootState } from '../Redux/makeReduxStore'
import {pageMinHeight, contentSideMargin, contentInternalMargin, APP_WIDTHS} from '../Styles/AppWrapper'
import Layout, { Content, Footer } from '../Components/Form/Layout'
import BackendPageHeader from './BackendPageHeader'

const BackendPageWrapper = styled.div`
  width: 100%;
  height: 100vh;

  @media only screen and (max-width: ${APP_WIDTHS.xs}) {
    .ant-layout-content {
      padding: 0px !important;
    }
    .hidden-xs {
      display: none;
    }
  }

  .content-header {
    margin-bottom: ${contentInternalMargin};
  }
`

type iBackendPage = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageHeader?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ContentHeader?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  footer?: any;
  checkPackage?: boolean;
}
/**
 * Backend page
 * @param children
 */
const BackendPage = ({ children, ContentHeader, checkPackage = false, footer, pageHeader }: iBackendPage) => {
  // eslint-disable-line
  // const dispatch = useDispatch()
  const { collapsed } = useSelector((s: RootState) => s.backendPage)
  // const { user, isAuthenticating } = useSelector((s: RootState) => s.auth)

  // const collapse = (isCollapsed: boolean) => {
  //   if (isCollapsed === true) {
  //     dispatch(collapseSideMenu())
  //   } else {
  //     dispatch(expandSideMenu())
  //   }
  // }

  const getChildren = () => {
    if (checkPackage === false) {
      return children
    }
    return children
  }

  /**
   * render
   */
  return (
    <BackendPageWrapper className={`backend-page-wrapper ${collapsed === true ? 'sider-collapsed' : ''}`}>
      <Layout className={'backend-page'} style={{ minHeight: `${pageMinHeight}` }}>

        <Layout className='site-layout'>
          {pageHeader === null ? null : (pageHeader || <BackendPageHeader />)}
          <Content
            className='site-layout-background'
            style={{
              margin: `24px ${contentSideMargin}`,
              padding: 24,
              minHeight: 280,
            }}
          >
            {ContentHeader ? <div className={'content-header'}>{ContentHeader}</div> : null}
            {getChildren()}
          </Content>
          {footer === null ? null : <Footer>
            {footer || <AppFooter />}
          </Footer>}
        </Layout>
      </Layout>
    </BackendPageWrapper>
  )
}

export default BackendPage
