import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AuthService from '../../Services/AuthService'

import {BACK_END_URL} from '../../UrlMap'
import Spin from '../Form/Spin'
import Alert from '../Form/Alert'
import Icons from '../Icons'
import SkeletonImage from '../Form/SkeletonImage'
import QRCode from 'qrcode.react';
import WeChatService from '../../Services/WeChatService';
import message from '../Form/message';
import MathHelper from '../../Helpers/MathHelper';
import Button from '../Form/Button';
import {STYLE_WECHAT_COLOR} from '../../Styles/AppWrapper';
import {FormattedMessage} from 'react-intl';

const WeChatQRCodeDiv = styled.div`
  text-align: center;
  width: 100%;
`
const WeChatQRCodeImg = styled.div`
  background: white;
  padding: 10px;
  max-width: 260px;
  min-width: 160px;
  margin: 10px auto;
`
const responseImgStyle = { width: '100%', height: 'auto' }

type iWeChatLoginForm = {
  className?: string;
}
/**
 * WeChatLoginForm
 * @constructor
 */
const WeChatLoginForm = ({className}: iWeChatLoginForm) => {
  const [loginUrl, setLoginUrl] = useState<string | null>(null);
  const [loginToken, setLoginToken] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [count, setCount] = useState(0);
  const [showReloadBtn, setShowReloadBtn] = useState(false);


  useEffect(() => {
    let isCanceled = false;

    setIsLoading(true);
    WeChatService.getLoginQrCodeUrl()
      .then(resp => {
        if (isCanceled) return;
        setLoginUrl(`${resp.url || ''}`.trim());
        setLoginToken(`${resp.token || ''}`.trim());
        setUserToken('');
        setShowReloadBtn(false);
      })
      .catch(err => {
        if (isCanceled) return;
        message.error(err.response.data.message);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });

    return () => {
      isCanceled = true;
    }
  }, [count])

  useEffect(() => {
    const token = `${loginToken || ''}`.trim();
    if(token === '') { return }

    let isCanceled = false;
    const start = (i = 0) => {
      return setTimeout(() => {
        WeChatService.getUserFromQrcode(token)
          .then(resp => {
            if (isCanceled) return;
            const authToken = `${resp.token || ''}`.trim();
            if (i < MathHelper.mul(3, 60) && resp.userId === null && resp.error === null && authToken === '') {
              start(MathHelper.add(i, 1));
              return;
            }
            if (authToken !== '') {
              setUserToken(authToken)
              return;
            }
            if (resp.error !== null) {
              message.error(`${resp.error.message || 'Error Occurred'}`);
            }
            setShowReloadBtn(true);
          })
          .catch(err => {
            if (isCanceled) return;
            message.error(err.response.data.message);
          })
          .finally(() => {
            if (isCanceled) return;
          });
      }, 1000)
    }

    start();
    return () => {
      isCanceled = true;
    }
  }, [loginToken])

  const getQrcode = () => {
    const refreshBtn = <small><p>Timed out, please click below button to refresh again.</p><Button onClick={() => setCount(MathHelper.add(count, 1))}>Refresh</Button></small>;
    if (showReloadBtn === true) {
      return refreshBtn
    }
    if (loginUrl === null) {
      return <SkeletonImage style={responseImgStyle}/>;
    }

    if (`${loginUrl || ''}`.trim() !== '') {
      return <QRCode value={`${loginUrl || ''}`.trim()} />
    }

    return refreshBtn
  }

  const getInfoDiv = () => (
    <Alert
      message={
        <Icons.WechatFilled style={{ fontSize: '48px', color: STYLE_WECHAT_COLOR }}/>
      }
      description={
        <div>
          <div>
            <FormattedMessage id={'weChatLoginTitle'} />
          </div>
          <WeChatQRCodeImg>
            {getQrcode()}
          </WeChatQRCodeImg>
        </div>
      }
      type="info"
    />
  );

  if (`${userToken || ''}`.trim() !== '') {
    AuthService.setLocalAuthToken(`${userToken || ''}`.trim());
    window.location.href = BACK_END_URL;
    return null;
  }

  /**
   * render
   */
  return (
    <WeChatQRCodeDiv className={className}>
      <Spin tip="Requesting QrCode ..." spinning={isLoading}>
        {getInfoDiv()}
      </Spin>
    </WeChatQRCodeDiv>
  );
}

export default WeChatLoginForm
