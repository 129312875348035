import BackendPage from '../Layouts/BackendPage';
import React, {useState, useRef, useEffect} from 'react';
import InitMessagePanel from '../Components/Message/InitMessagePanel';
import iMessage, {CHAT_TYPE_CHAT, CHAT_TYPE_IMAGE} from '../Types/iMessage';
import MessagesPanel from '../Components/Message/MessagesPanel';
import {Container} from '../Styles/AppWrapper';
import Icons from '../Components/Icons';
import InputBox from '../Components/Form/InputBox';
import {useIntl} from 'react-intl';
import AiService from '../Services/AiService';
import message from '../Components/Form/message';
import MessageRow from '../Components/Message/MessageRow';
import LocalStorageService from '../Services/LocalStorageService';
import styled from 'styled-components';
import BackendPageHeader from '../Layouts/BackendPageHeader';
import Select, {Option} from '../Components/Form/Select';
import Col from '../Components/Form/Col';


const Wrapper = styled.div`
  padding-bottom: 3rem;
  margin-top: 5rem;
  .input-box-wrapper {
    background: rgba(23, 23, 23, 0.75);
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: .5rem 0.3rem;
    
    .app-Select {
      .ant-select-selector {
        background-color: white !important;
      }
    }
  }
  
  .top-menu {
    z-index: 999;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    border-bottom: 1px #ccc solid;
    box-shadow: 0 8px 8px -4px #ccc;
  }
`;
const MainPage = () => {
  const intl = useIntl();
  const [messages, setMessages] = useState<iMessage[]>([]);
  const [userMsg, setUserMsg] = useState('');
  const [isSending, setIsSending] = useState(false);
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [chatType, setChatType] = useState<string>(CHAT_TYPE_CHAT);


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const readChunk = (reader: ReadableStreamDefaultReader,  previousMsg = '', previousLine = ''): any =>  {
    return reader.read().then(({ done, value }) => {
      if (done) {
        // console.log('DONE');
        setMessages((prevMessages) => [...(prevMessages || []), {content: previousMsg, role: 'System'}]);
        setIsSending(false);
        return previousMsg;
      }
      const decoder = new TextDecoder();
      const message = decoder.decode(value);

      const lines = `${previousLine}${message}`
        .split('\n')
        .filter((line: string) => line.trim() !== '');
      let newMsg = '';
      let lastLine = '';
      // let isDone = false;
      for (const line of lines) {
        const msg = line.replace(/^data: /, '');
        try {
          if (msg !== '[DONE]') {
            // console.log('msg', msg);
            const parsed = JSON.parse(msg);
            newMsg = `${newMsg}${parsed.choices[0].delta.content || ''}`;
            if (document.getElementById('response-id')) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              document.getElementById('response-id').innerHTML += parsed.choices[0].delta.content || '';
            }
          }
        } catch (error) {
          // console.error('Could not JSON parse stream message', message, error);
          lastLine = line;
        }
        // setSystemResponseMsg({
        //   ...systemResponseMsg,
        //   role: 'System',
        //   content: `${systemResponseMsg?.content || ''}${newMsg}`,
        // })
      }

      // Read the next chunk
      return readChunk(reader, `${previousMsg}${newMsg}`, lastLine);
    });
  }

  useEffect(() => {
    if (messages.length <= 0) {
      return;
    }
    LocalStorageService.setItem(`${chatType}_messages`, JSON.stringify(messages));
    messageContainerRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages]);

  useEffect(() => {
    setMessages(JSON.parse(LocalStorageService.getItem(`${chatType}_messages`) || '[]'))
  }, [chatType])


  const sendMessage = () => {
    const msg = `${userMsg}`.trim();
    if (msg === '') {
      return;
    }

    setIsSending(true);
    setMessages((prevMessages) => [...(prevMessages || []), {content: msg, role: 'User'}]);
    setUserMsg('');
    AiService.chatStream(msg)
      .then(resp => {
        if (resp.body) {
          const reader = resp.body.getReader();
          return readChunk(reader);
        }
      })
      .catch(err => {
        message.error(err.message || err.data.response.message)
      })
  }

  const genImage = () => {
    const msg = `${userMsg}`.trim();
    if (msg === '') {
      return;
    }

    setIsSending(true);
    setMessages((prevMessages) => [...(prevMessages || []), {content: msg, role: 'User'}]);
    setUserMsg('');
    AiService.genImage(msg)
      .then(resp => {
        setMessages((prevMessages) => [...(prevMessages || []), {content: `${resp.data[0].url || ''}`, role: 'System'}]);
      })
      .catch(err => {
        message.error(err.message || err.data.response.message)
      })
      .finally(() => {
        setIsSending(false);
      })
  }

  const submit = () => {
    if (chatType === CHAT_TYPE_CHAT) {
      sendMessage();
      return;
    }
    genImage()
  }

  return (
    <BackendPage footer={null} pageHeader={null}>
      <Wrapper>
        <div className={'top-menu'}>
          <BackendPageHeader
            title={
              <Col
                flex={'100px'}
                style={{
                  textAlign: 'right',
                }}
              >
                {intl.formatMessage({id: chatType})}
              </Col>
            }
          />
        </div>
        {messages.length <= 0 ? <InitMessagePanel /> : (
          <MessagesPanel messages={messages} chatType={chatType} >
            {isSending === true ? (
              <MessageRow msg={{ role: 'System', isLoading: true, content: intl.formatMessage({id: 'thinking'})}} id={'response-id'} />
            ) : null}
          </MessagesPanel>
        )}
        <div ref={messageContainerRef} />
        <div className={'input-box-wrapper'}>
          <Container>
            <InputBox
              addonBefore={
                <Select value={chatType} onChange={(value) => setChatType(value)}>
                  <Option value={CHAT_TYPE_CHAT}>{intl.formatMessage({id: 'chat'})}</Option>
                  <Option value={CHAT_TYPE_IMAGE}>{intl.formatMessage({id: 'image'})}</Option>
                </Select>
              }
              disabled={isSending === true}
              value={userMsg}
              placeholder={intl.formatMessage({id: 'userQuestionBoxPlaceHolder'})}
              onChange={(event) => setUserMsg(event.target.value)}
              suffix={<Icons.SendOutlined onClick={() => submit()}/>}
              onPressEnter={() => submit()}
            />
          </Container>
        </div>
      </Wrapper>
    </BackendPage>
  );
}

export default MainPage;
